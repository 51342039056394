import React from "react";
import Styles from './styles.module.scss'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


const EventNyMain = (props) => {
    const {coins} = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrapItem}`}>
                    <div className={`${Styles.titleWSF}`}>Honee <span className={`${Styles.titleWSF}`}>NY</span></div>
                    <div className={`${Styles.titleWSF}`}>{t('Баланс ваших монет')}</div>
                    <div className={`${Styles.contentWrapItemValue}`}>{coins}</div>
                    <div className={`${Styles.inviteBtn}`} onClick={()=>{navigate('/news/_omt_new_years_eve_promotion__n9')}}>{t('Подробнее')}</div>
                </div>
                <div className={`${Styles.noteWrap}`}>
                    <div className={`${Styles.noteItem}`}>
                        {t('Новогодний ивент')} <span>Honee New Year</span> – {t('это твой шанс завершить год с невероятными призами')}!
                    </div>
                    <div className={`${Styles.noteItem}`}>
                        {t('Участвуй в нашем праздничном мероприятии, выполняй задания каждую неделю и зарабатывай жетоны')}. <span>Honee New Year</span> – {t('это весело, просто и выгодно. Выполняй задания, зарабатывай бонусы за полное прохождение недель, пополняй HoneeAI и получай ещё больше жетонов. Каждый жетон приближает тебя к победе и делает твоё участие незабываемым. Не упусти возможность завершить год на волне успеха вместе с ОМТ')}!
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EventNyMain;
