import React, {useEffect} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getPromotionsNyMainData} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import Seo from "../../components/_UI/seo";
import EventNyMain from "../../components/eventNyMain";
import EventNyItem from "../../components/eventNyItem";

import tree0_1 from '../../theme/assets/ny/swap_tree_0_1.png';
import tree0_2 from '../../theme/assets/ny/swap_tree_0_2.png';
import tree0_3 from '../../theme/assets/ny/swap_tree_0_3.png';
import tree0_4 from '../../theme/assets/ny/swap_tree_0_4.png';
import tree1_1 from '../../theme/assets/ny/swap_tree_1_1.png';
import tree1_2 from '../../theme/assets/ny/swap_tree_1_2.png';
import tree1_3 from '../../theme/assets/ny/swap_tree_1_3.png';
import tree1_4 from '../../theme/assets/ny/swap_tree_1_4.png';
import tree2_1 from '../../theme/assets/ny/swap_tree_2_1.png';
import tree2_2 from '../../theme/assets/ny/swap_tree_2_2.png';
import tree2_3 from '../../theme/assets/ny/swap_tree_2_3.png';
import tree2_4 from '../../theme/assets/ny/swap_tree_2_4.png';
import tree3_1 from '../../theme/assets/ny/swap_tree_3_1.png';
import tree3_2 from '../../theme/assets/ny/swap_tree_3_2.png';
import tree3_3 from '../../theme/assets/ny/swap_tree_3_3.png';
import tree3_4 from '../../theme/assets/ny/swap_tree_3_4.png';
import tree4_1 from '../../theme/assets/ny/swap_tree_4_1.png';
import tree4_2 from '../../theme/assets/ny/swap_tree_4_2.png';
import tree4_3 from '../../theme/assets/ny/swap_tree_4_3.png';
import tree4_4 from '../../theme/assets/ny/swap_tree_4_4.png';
import EventNyTaskModal from "../../components/eventNyTaskModal";


const EventNyPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const promotionsNyMainData = useSelector(store => store.cabinet.promotionsNyMainData);

    const styleData = [
        {
            color: '#FFFF00',
            background: 'linear-gradient(138deg, rgba(255, 255, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%)',
            imgs: [tree0_1, tree1_1, tree2_1, tree3_1, tree4_1]
        },
        {
            color: '#00FF7A',
            background: 'linear-gradient(138deg, rgba(0, 255, 122, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%)',
            imgs: [tree0_2, tree1_2, tree2_2, tree3_2, tree4_2]
        },
        {
            color: '#FF8C00',
            background: 'linear-gradient(138deg, rgba(255, 140, 0, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%)',
            imgs: [tree0_3, tree1_3, tree2_3, tree3_3, tree4_3]
        },
        {
            color: '#00FFFF',
            background: 'linear-gradient(138deg, rgba(0, 255, 122, 0.15) -23.04%, rgba(255, 255, 255, 0.01) 118.95%)',
            imgs: [tree0_4, tree1_4, tree2_4, tree3_4, tree4_4]
        },
    ]

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])
    
    useEffect(()=>{
        dispatch(getPromotionsNyMainData());
    },[dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={`Honee NY`}/>
            <div className={`${Styles.contentWrap}`}>
                <EventNyMain coins={promotionsNyMainData ? promotionsNyMainData.coins : 0}/>
                <div className={`${Styles.taskWrap}`}>
                    <div className={`${Styles.taskTitle} ${Styles.titleWSF}`}>{t('Задания')}:</div>
                        <div className={`${Styles.taskBox}`}>
                            {promotionsNyMainData?.rounds ? promotionsNyMainData?.rounds.map((item, index)=>{
                                return (
                                    <EventNyItem item={item} index={index} styleData={styleData} key={'nyRound' + index}/>
                                )
                            }) : null}
                        </div>
                    <div className={`${Styles.mobileCompleteWrap}`}>
                        <div className={`${Styles.mobileCompleteTitle} ${Styles.titleWSF}`}>{t('Выполнено')}:</div>
                        <div className={`${Styles.mobileCompleteRow}`}>
                            {promotionsNyMainData?.rounds ? promotionsNyMainData?.rounds.map((item, index) => {
                                return (
                                    <div className={`${Styles.mobileCompleteRowItem} ${Styles.titleWSF}`} key={'mobileComplete' + index}
                                         style={{
                                             border: `1px solid ${styleData[index].color}`,
                                             background: `${styleData[index].background}`,
                                             color: `${styleData[index].color}`
                                         }}>{item.completed_tasks}/5</div>
                                )
                            }) : null}
                        </div>
                    </div>
                </div>
                {/*<EventNyWheel/>*/}
                <EventNyTaskModal/>
            </div>
        </section>
    );
};

export default EventNyPage;