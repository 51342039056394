import React, {useEffect, useState} from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    closeOmtStaking,
    getOneCurrentWallet,
    getStakingCloseCommission,
    setStakingCloseFormStatus,
    withdrawStaking
} from "../../store/cabinet/action";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const StakingCloseForm = () => {
    const dispatch = useDispatch();
    const stakingFormStatus = useSelector(store => store.cabinet.stakingCloseFormStatus);
    // const oneCurrentWallet = useSelector(store => store.wallet.oneCurrentWallet);
    const stakingAward = useSelector(store => store.cabinet.stakingCloseCommission);

    const { t } = useTranslation();

    const [sum, setSum] = useState('');

    const commissionContent = () =>{
        if (stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20'){
            return <span>{stakingAward?.commission || stakingAward?.commission === 0 ? `${Number(stakingAward?.commission)} ${stakingAward?.currency?.short_name}` : t('Загрузка...')} {stakingAward?.commission ? isCommissionSuccess() ? <span className={`${Styles.colorGreen}`}>({t('Достаточно')})</span> : <span className={`${Styles.colorRed}`}>({t('Недостаточно')})</span> : null}</span>
        } else {
            return <span>{stakingFormStatus?.item?.currency?.withdraw_commission} {stakingFormStatus?.item?.currency?.short_name}</span>
        }
    }


    const closeModal = () =>{
        dispatch(setStakingCloseFormStatus({status: false, item: false, balance: false}));
        setSum('')
    }

    const isCommissionSuccess = () =>{
        return Number(stakingAward?.wallet?.amount) >= stakingAward?.commission
    }

    const mainBtnHandler = () =>{
        const successCallback = () =>{
            closeModal();
            toast(t('Ваша заявка на закрытие стейкинга успешно отправлена. Ожидайте завершения транзакции!'))
        }

        if (isSubmitButtonAvailable()){
            if (stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20'){
                dispatch(closeOmtStaking({id: stakingFormStatus.item.id, successCallback}))
            } else {
                dispatch(
                    withdrawStaking({
                        sum,
                        id: stakingFormStatus.item.id,
                        successCallback
                    })
                );
            }

            // closeModal();
            // toast(t('Ваши активы успешно выведены со стейкинга!'))
        }
    }

    const isSubmitButtonAvailable = () => {
        if (!stakingFormStatus?.item?.is_pending_withdraw) {
            if (stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20') {
                return availableForInvest() > 0 && isCommissionSuccess();
            } else {
                if (stakingAward?.currency?.short_name === stakingFormStatus?.item?.currency?.short_name) {
                    return sum >= minWithdrawalValue() && availableForInvest() >= minWithdrawalValue() && Number(stakingFormStatus?.item?.currency?.withdraw_commission) + Number(sum) <= availableForInvest();
                } else {
                    return sum >= minWithdrawalValue() && availableForInvest() >= minWithdrawalValue() && sum <= availableForInvest();
                }
            }
        }

    }

    const minWithdrawalValue = () => {
        return stakingFormStatus?.item?.staking?.min_amount_for_withdraw * 1;
    }

    const availableForInvest = () => {
        if (stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20'){
            // return Number(stakingFormStatus?.item?.total);
            return true
        } else {
            return Number(stakingFormStatus?.item?.profit).toFixed(2) * 1;
        }
    }

    const placeholder = () => {
        var message = `${t('Количество от')} ${minWithdrawalValue()}`;

        if (minWithdrawalValue() < availableForInvest()) {
            message += ` ${t('до')} ${stakingFormStatus?.item?.profit ? availableForInvest() : '0'}`;
        }
        return message;
    }

    useEffect(()=>{
        if (stakingFormStatus?.item?.currency?.id){
            if (stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20'){
                dispatch(getStakingCloseCommission(stakingFormStatus.item.currency.id))
                setSum(Number(stakingFormStatus?.item?.total))
            } else {
                dispatch(getOneCurrentWallet(stakingFormStatus.item.currency.id));
            }
        }

    },[dispatch, stakingFormStatus])


    return (
        <Popup
            open={stakingFormStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.formWrap}`}>
                        <div className={`${Styles.formTitle}`}>{t('Стейкинг')} {t('Закрыть')}</div>
                        <div className={`${Styles.inputWrap}`}>
                            <div className={`${Styles.inputBox}`}>
                                <input
                                    type="number"
                                    placeholder={`${placeholder()}`}
                                    onChange={(e)=>{
                                        if (e.target.value < 0){
                                            setSum(0)
                                        } else {
                                            setSum(e.target.value)
                                        }
                                    }}
                                    className={`${Styles.inputSum}`}
                                    value={sum}
                                    readOnly={stakingFormStatus?.item?.currency?.short_name === 'OMT_BEP20'}
                                />
                                <div className={`${Styles.inputAbs}`} onClick={()=>{setSum(availableForInvest())}}>
                                    <span className={`${Styles.inputAbsCur}`}>{stakingFormStatus?.item?.currency?.short_name}</span>
                                    <span className={`${Styles.inputAbsBtn}`}>{t('Все')}</span>
                                </div>
                                <div className={`${Styles.inputNote}`}>{t('Доступно для вывода')}: <span>{stakingFormStatus?.item?.profit ? availableForInvest() : '0'} {stakingFormStatus?.item?.currency?.short_name}</span></div>
                                <div className={`${Styles.inputNote}`}>{t('Коммиссия')}: {commissionContent()}</div>
                            </div>
                            <div className={`${Styles.mainBtn} ${isSubmitButtonAvailable() ? Styles.mainBtnActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Закрыть')}</div>
                        </div>
                        <div className={`${Styles.mainBtnM} ${isSubmitButtonAvailable() ? Styles.mainBtnMActive : null}`} onClick={()=>{mainBtnHandler()}}>{t('Закрыть')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default StakingCloseForm;
