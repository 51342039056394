import {
    TEST,
    SET_MOBILE_NAV_STATUS,
    GET_BOT_MAIN_DATA_STARTED,
    GET_BOT_MAIN_DATA_SUCCESS,
    GET_BOT_MAIN_DATA_FAILURE,
    SEND_SUPPORT_FORM_STARTED,
    SEND_SUPPORT_FORM_SUCCESS,
    SEND_SUPPORT_FORM_FAILURE,
    GET_BOT_SUBSCRIBE_INFO_STARTED,
    GET_BOT_SUBSCRIBE_INFO_SUCCESS,
    GET_BOT_SUBSCRIBE_INFO_FAILURE,
    SET_BOT_SUBSCRIBE_MODAL_STATUS,
    SET_BONUSES_MODAL_STATUS,
    GET_REF_MAIN_DATA_STARTED,
    GET_REF_MAIN_DATA_SUCCESS,
    GET_REF_MAIN_DATA_FAILURE,
    GET_REF_INFO_STARTED,
    GET_REF_INFO_SUCCESS,
    GET_REF_INFO_FAILURE,
    GET_REF_LOGS_STARTED,
    GET_REF_LOGS_SUCCESS,
    GET_REF_LOGS_FAILURE,
    SET_REF_LOGS_CORRECT_DATA,
    SET_REF_LOGS_PAGE_DATA,
    SET_AMBASSADORS_MODAL_STATUS,
    GET_AMBASSADOR_MAIN_DATA_STARTED,
    GET_AMBASSADOR_MAIN_DATA_SUCCESS,
    GET_AMBASSADOR_MAIN_DATA_FAILURE,
    GET_AMBASSADOR_STATUSES_STARTED,
    GET_AMBASSADOR_STATUSES_SUCCESS,
    GET_AMBASSADOR_STATUSES_FAILURE,
    GET_AMBASSADOR_GRAPH_STARTED,
    GET_AMBASSADOR_GRAPH_SUCCESS,
    GET_AMBASSADOR_GRAPH_FAILURE,
    GET_AMBASSADOR_GRAPH_CORRECT_DATA,
    GET_AMBASSADOR_LINKS_STARTED,
    GET_AMBASSADOR_LINKS_SUCCESS,
    GET_AMBASSADOR_LINKS_FAILURE,
    SEND_AMBASSADOR_LINK_STARTED,
    SEND_AMBASSADOR_LINK_SUCCESS,
    SEND_AMBASSADOR_LINK_FAILURE,
    GET_AMBASSADOR_TURNOVER_STARTED,
    GET_AMBASSADOR_TURNOVER_SUCCESS,
    GET_AMBASSADOR_TURNOVER_FAILURE,
    AMBASSADOR_LINK_ERRORS,
    AMBASSADOR_LINK_ERRORS_CLEAR,
    AMBASSADOR_CORRECT_LINKS,
    SET_AMBASSADORS_MODAL_SALARY_STATUS,
    GET_AMBASSADOR_SALARY_DATA_STARTED,
    GET_AMBASSADOR_SALARY_DATA_SUCCESS,
    GET_AMBASSADOR_SALARY_DATA_FAILURE,
    SET_INFLUENCER_MODAL_STATUS,
    CHANGE_PASSWORD_STARTED,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    GET_HONEECHIZE_MAIN_DATA_STARTED,
    GET_HONEECHIZE_MAIN_DATA_SUCCESS,
    GET_HONEECHIZE_MAIN_DATA_FAILURE,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS,
    GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE,
    SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS,
    SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS,
    CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE,
    GET_HONEECHIZE_BOT_EARNINGS_STARTED,
    GET_HONEECHIZE_BOT_EARNINGS_SUCCESS,
    GET_HONEECHIZE_BOT_EARNINGS_FAILURE,
    GET_HONEECHIZE_BOT_SETTINGS_STARTED,
    GET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    GET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    SET_HONEECHIZE_BOT_SETTINGS_STARTED,
    SET_HONEECHIZE_BOT_SETTINGS_SUCCESS,
    SET_HONEECHIZE_BOT_SETTINGS_FAILURE,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS,
    GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE,
    SET_HONEECHIZE_ALERT_MODAL_STATUS,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA,
    SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA,
    GET_CUSTOM_SETTINGS_STARTED,
    GET_CUSTOM_SETTINGS_SUCCESS,
    GET_CUSTOM_SETTINGS_FAILURE,
    GET_INDEX_MAIN_DATA_STARTED,
    GET_INDEX_MAIN_DATA_SUCCESS,
    GET_INDEX_MAIN_DATA_FAILURE,
    INDEX_SWAP_STARTED,
    INDEX_SWAP_SUCCESS,
    INDEX_SWAP_FAILURE,
    GET_INDEX_SWAP_HISTORY_STARTED,
    GET_INDEX_SWAP_HISTORY_SUCCESS,
    GET_INDEX_SWAP_HISTORY_FAILURE,
    GET_INDEX_SWAP_PREDICTION_STARTED,
    GET_INDEX_SWAP_PREDICTION_SUCCESS,
    GET_INDEX_SWAP_PREDICTION_FAILURE,
    CHANGE_BOT_RISK_STARTED,
    CHANGE_BOT_RISK_SUCCESS,
    CHANGE_BOT_RISK_FAILURE,
    SET_BOT_PROMO_MODAL_STATUS,
    BOT_INVEST_STARTED,
    BOT_INVEST_SUCCESS,
    BOT_INVEST_FAILURE,
    OPEN_BOT_STARTED,
    OPEN_BOT_SUCCESS,
    OPEN_BOT_FAILURE,
    CONTROL_BOT_STARTED,
    CONTROL_BOT_SUCCESS,
    CONTROL_BOT_FAILURE,
    SET_BOT_REINVEST_STARTED,
    SET_BOT_REINVEST_SUCCESS,
    SET_BOT_REINVEST_FAILURE,
    GET_BOT_HISTORY_STARTED,
    GET_BOT_HISTORY_SUCCESS,
    GET_BOT_HISTORY_FAILURE,
    SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS,
    GET_CRYPTO_NEWS_STARTED,
    GET_CRYPTO_NEWS_SUCCESS,
    GET_CRYPTO_NEWS_FAILURE,
    GET_INDICATORS_STARTED,
    GET_INDICATORS_SUCCESS,
    GET_INDICATORS_FAILURE,
    GET_AVAILABLE_CURRENCIES_STARTED,
    GET_AVAILABLE_CURRENCIES_SUCCESS,
    GET_AVAILABLE_CURRENCIES_FAILURE,
    SET_CREATE_WALLET_CURRENCIES,
    SET_WALLET_CREATE_FORM_STATUS,
    CREATE_WALLET_STARTED,
    CREATE_WALLET_SUCCESS,
    CREATE_WALLET_FAILURE,
    GET_WALLET_ASSETS_SUCCESS,
    GET_WALLET_ASSETS_STARTED,
    GET_WALLET_ASSETS_FAILURE,
    GET_WALLET_ASSETS_WITH_BALANCE,
    GET_REFILLABLE_WALLETS,
    SET_CURRENT_WALLET,
    CREATE_CURRENT_WALLET_STARTED,
    CREATE_CURRENT_WALLET_SUCCESS,
    CREATE_CURRENT_WALLET_FAILURE,
    SET_WALLET_TAKE_FORM_STATUS,
    SET_WALLET_SEND_FORM_STATUS,
    GET_TRANSFER_COMMISSION_STARTED,
    GET_TRANSFER_COMMISSION_SUCCESS,
    GET_TRANSFER_COMMISSION_FAILURE,
    SEND_TRANSACTIONS_STARTED,
    SEND_TRANSACTIONS_SUCCESS,
    SEND_TRANSACTIONS_FAILURE,
    GET_WALLET_GRAPH_HISTORY_STARTED,
    GET_WALLET_GRAPH_HISTORY_SUCCESS,
    GET_WALLET_GRAPH_HISTORY_FAILURE,
    GET_OMT_PERIODS_STARTED,
    GET_OMT_PERIODS_SUCCESS,
    GET_OMT_PERIODS_FAILURE,
    GET_AVAILABLE_WALLETS_STARTED,
    GET_AVAILABLE_WALLETS_SUCCESS,
    GET_AVAILABLE_WALLETS_FAILURE,
    GET_CURRENT_WALLET_STARTED,
    GET_CURRENT_WALLET_SUCCESS,
    GET_CURRENT_WALLET_FAILURE,
    GET_CURRENT_WALLET_TRANSACTIONS_STARTED,
    GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS,
    GET_CURRENT_WALLET_TRANSACTIONS_FAILURE,
    SET_STAKING_FORM_STATUS,
    BALANCE_TRANSACTIONS_STARTED,
    BALANCE_TRANSACTIONS_SUCCESS,
    BALANCE_TRANSACTIONS_FAILURE,
    SET_STAKING_BALANCE_STATUS,
    GET_ONE_CURRENT_WALLET_STARTED,
    GET_ONE_CURRENT_WALLET_SUCCESS,
    GET_ONE_CURRENT_WALLET_FAILURE,
    OPEN_STAKING_STARTED,
    OPEN_STAKING_SUCCESS,
    OPEN_STAKING_FAILURE,
    GET_AVAILABLE_STAKING_STARTED,
    GET_AVAILABLE_STAKING_SUCCESS,
    GET_AVAILABLE_STAKING_FAILURE,
    SET_STAKING_FORM_OMT_STATUS,
    GET_STAKING_COMMISSION_STARTED,
    GET_STAKING_COMMISSION_SUCCESS,
    GET_STAKING_COMMISSION_FAILURE,
    SET_STAKING_WITHDRAW_FORM_STATUS,
    GET_STAKING_AWARD_STARTED,
    GET_STAKING_AWARD_SUCCESS,
    GET_STAKING_AWARD_FAILURE,
    WITHDRAW_OMT_STAKING_STARTED,
    WITHDRAW_OMT_STAKING_SUCCESS,
    WITHDRAW_OMT_STAKING_FAILURE,
    WITHDRAW_STAKING_STARTED,
    WITHDRAW_STAKING_SUCCESS,
    WITHDRAW_STAKING_FAILURE,
    EXCHANGE_STARTED,
    EXCHANGE_SUCCESS,
    EXCHANGE_FAILURE,
    GET_EXCHANGE_HISTORY_STARTED,
    GET_EXCHANGE_HISTORY_SUCCESS,
    GET_EXCHANGE_HISTORY_FAILURE,
    GET_EXCHANGE_DATA_STARTED,
    GET_EXCHANGE_DATA_SUCCESS,
    GET_EXCHANGE_DATA_FAILURE,
    GET_WALLET_TRANSACTIONS_STARTED,
    GET_WALLET_TRANSACTIONS_SUCCESS,
    GET_WALLET_TRANSACTIONS_FAILURE,
    SET_BOT_MODAL_STATUS,
    SET_CUSTOM_SETTINGS_STARTED,
    SET_CUSTOM_SETTINGS_SUCCESS,
    SET_CUSTOM_SETTINGS_FAILURE,
    BUY_SUBSCRIBE_STARTED,
    BUY_SUBSCRIBE_SUCCESS,
    BUY_SUBSCRIBE_FAILURE,
    GET_BOT_PROFIT_STARTED,
    GET_BOT_PROFIT_SUCCESS,
    GET_BOT_PROFIT_FAILURE,
    SET_BOT_PRO_PLUS_MODAL_STATUS,
    CLEAR_CABINET_STATE,
    GET_SUBSCRIBE_COMMISSION_STARTED,
    GET_SUBSCRIBE_COMMISSION_SUCCESS,
    GET_SUBSCRIBE_COMMISSION_FAILURE,
    SET_GREETING_STARTED,
    SET_GREETING_SUCCESS,
    SET_GREETING_FAILURE,
    GET_EXCHANGE_COMMISSION_STARTED,
    GET_EXCHANGE_COMMISSION_SUCCESS,
    GET_EXCHANGE_COMMISSION_FAILURE,
    GET_OMT_TOKEN_STARTED,
    GET_OMT_TOKEN_SUCCESS,
    GET_OMT_TOKEN_FAILURE,
    SET_INDEX_FREEZE_MODAL_STATUS,
    FREEZE_TOKEN_STARTED,
    FREEZE_TOKEN_SUCCESS,
    FREEZE_TOKEN_FAILURE,
    GET_FREEZE_COMMISSION_STARTED,
    GET_FREEZE_COMMISSION_SUCCESS,
    GET_FREEZE_COMMISSION_FAILURE,
    GET_ACTIVE_FREEZE_STARTED,
    GET_ACTIVE_FREEZE_SUCCESS,
    GET_ACTIVE_FREEZE_FAILURE,
    SET_FREEZE_REINVEST_STARTED,
    SET_FREEZE_REINVEST_SUCCESS,
    SET_FREEZE_REINVEST_FAILURE,
    FREEZE_WITHDRAW_STARTED,
    FREEZE_WITHDRAW_SUCCESS,
    FREEZE_WITHDRAW_FAILURE,
    GET_FREEZE_TOTAL_STARTED,
    GET_FREEZE_TOTAL_SUCCESS,
    GET_FREEZE_TOTAL_FAILURE,
    CLEAR_INDEX_LIST,
    SET_SWAP_REQUEST_MODAL_STATUS,
    GET_SWAP_MAIN_DATA_STARTED,
    GET_SWAP_MAIN_DATA_SUCCESS,
    GET_SWAP_MAIN_DATA_FAILURE,
    SET_SWAP_REQUEST_CANCEL_MODAL_STATUS,
    GET_SWAP_COMMISSION_STARTED,
    GET_SWAP_COMMISSION_SUCCESS,
    GET_SWAP_COMMISSION_FAILURE,
    CREATE_BUY_SWAP_REQUEST_STARTED,
    CREATE_BUY_SWAP_REQUEST_SUCCESS,
    CREATE_BUY_SWAP_REQUEST_FAILURE,
    CREATE_SELL_SWAP_REQUEST_STARTED,
    CREATE_SELL_SWAP_REQUEST_SUCCESS,
    CREATE_SELL_SWAP_REQUEST_FAILURE,
    GET_MY_SWAP_REQUESTS_STARTED,
    GET_MY_SWAP_REQUESTS_SUCCESS,
    GET_MY_SWAP_REQUESTS_FAILURE,
    CANCEL_MY_SWAP_REQUEST_STARTED,
    CANCEL_MY_SWAP_REQUEST_SUCCESS,
    CANCEL_MY_SWAP_REQUEST_FAILURE,
    CLEAR_MY_SWAP_REQUEST_LIST,
    CLEAR_ALL_SWAP_REQUEST_LIST,
    GET_ALL_SWAP_REQUESTS_STARTED,
    GET_ALL_SWAP_REQUESTS_SUCCESS,
    GET_ALL_SWAP_REQUESTS_FAILURE,
    SET_SWAP_REQUEST_CONFIRM_MODAL_STATUS,
    CONFIRM_BUY_SWAP_REQUEST_STARTED,
    CONFIRM_BUY_SWAP_REQUEST_SUCCESS,
    CONFIRM_BUY_SWAP_REQUEST_FAILURE,
    CONFIRM_SELL_SWAP_REQUEST_STARTED,
    CONFIRM_SELL_SWAP_REQUEST_SUCCESS,
    CONFIRM_SELL_SWAP_REQUEST_FAILURE,
    SET_STAKING_CLOSE_FORM_STATUS,
    GET_STAKING_CLOSE_COMMISSION_STARTED,
    GET_STAKING_CLOSE_COMMISSION_SUCCESS,
    GET_STAKING_CLOSE_COMMISSION_FAILURE,
    CLOSE_OMT_STAKING_STARTED,
    CLOSE_OMT_STAKING_SUCCESS,
    CLOSE_OMT_STAKING_FAILURE,
    GET_PROMOTIONS_NY_MAIN_DATA_STARTED,
    GET_PROMOTIONS_NY_MAIN_DATA_SUCCESS,
    GET_PROMOTIONS_NY_MAIN_DATA_FAILURE,
    SET_EVENT_NY_TASK_MODAL_STATUS
} from "./types";


const initialState = {
    test: false,
    mobileNavStatus: false,
    bonusesModalStatus: false,
    swapRequestModalStatus: false,
    swapRequestConfirmModalStatus: {status: false, data: false, currentFilter: false},
    swapRequestCancelModalStatus: {status: false, data: false},
    eventNyTaskModalStatus: {status: false, data: false},
    indexFreezeModalStatus: false,
    influencerModalStatus: false,
    stakingFormStatus: {status: false, item: false, balance: false},
    stakingBalanceStatus: {status: false, type: false, balance: false, withdrawBalance: false},
    stakingFormOmtStatus: {status: false, item: false, balance: false},
    stakingWithdrawFormStatus: {status: false, item: false, balance: false},
    stakingCloseFormStatus: {status: false, item: false, balance: false},
    honeechizeAlertModalStatus: false,
    honeechizeAffiliateSettingsModalStatus: {status: false, data: false},
    botPromoModalStatus: {status: false, balance: false, sendAction: false, botId: false, isOpen: false, currentRisk: false},
    honeechizeAffiliateTableModalStatus: false,
    walletTransactionsStarted: false,
    walletTransactions: false,
    walletTransactionsErr: false,
    botMainDataStarted: false,
    botMainData: false,
    botMainDataErr: false,
    supportFormStarted: false,
    supportForm: false,
    supportFormErr: false,
    botSubscribeInfoStarted: false,
    botSubscribeInfo: false,
    botSubscribeInfoErr: false,
    botSubscribeModalStatus: false,
    refMainDataStarted: false,
    refMainData: false,
    refMainDataErr: false,
    refInfoStarted: false,
    refInfo: false,
    refInfoErr: false,
    refLogsStarted: false,
    refLogs: false,
    refLogsCorrectData: false,
    refLogsErr: false,
    refLogsPageData: false,
    ambassadorStatusesStarted: false,
    ambassadorStatuses: false,
    ambassadorStatusesErr: false,
    ambassadorGraphStarted: false,
    ambassadorGraph: false,
    ambassadorGraphErr: false,
    ambassadorGraphCorrectData: false,
    ambassadorLinksStarted: false,
    ambassadorCorrectLinks: false,
    ambassadorLinks: false,
    ambassadorLinksErr: false,
    ambassadorLinksErrors: {},
    ambassadorTurnoverStarted: false,
    ambassadorTurnover: false,
    ambassadorTurnoverErr: false,
    ambassadorMainDataStarted: false,
    ambassadorMainData: false,
    ambassadorMainDataErr: false,
    ambassadorsModalStatus: false,
    ambassadorsModalSalaryStatus: false,
    changePasswordStatusStarted: false,
    changePasswordStatus: false,
    changePasswordStatusErr: false,
    honeechizeMainDataStarted: false,
    honeechizeMainData: false,
    honeechizeMainDataErr: false,
    honeechizeAffiliateSettingsStarted: false,
    honeechizeAffiliateSettings: false,
    honeechizeAffiliateSettingsErr: false,
    changeHoneechizeAffiliatePercentStarted: false,
    changeHoneechizeAffiliatePercent: false,
    changeHoneechizeAffiliatePercentErr: false,
    honeechizeBotEarningsDataStarted: false,
    honeechizeBotEarningsData: false,
    honeechizeBotEarningsDataErr: false,
    honeechizeBotSettingsDataStarted: false,
    honeechizeBotSettingsData: false,
    honeechizeBotSettingsDataErr: false,
    setHoneechizeBotSettingsDataStarted: false,
    setHoneechizeBotSettingsData: false,
    setHoneechizeBotSettingsDataErr: false,
    honeechizeAffiliateStatisticStarted: false,
    honeechizeAffiliateStatistic: false,
    honeechizeAffiliateStatisticErr: false,
    honeechizeAffiliateStatisticPageData: false,
    honeechizeAffiliateStatisticCorrectData: false,
    honeechizeEarningsGraphDataStarted: false,
    honeechizeEarningsGraphData: false,
    honeechizeEarningsGraphDataErr: false,
    honeechizeProfitGraphDataStarted: false,
    honeechizeProfitGraphData: false,
    honeechizeProfitGraphDataErr: false,
    customSettingsStarted: false,
    customSettings: false,
    customSettingsErr: false,
    indexMainDataStarted: false,
    indexMainData: false,
    indexMainDataErr: false,
    indexSwapStatusStarted: false,
    indexSwapStatus: false,
    indexSwapStatusErr: false,
    indexSwapHistoryStarted: false,
    indexSwapHistory: false,
    indexSwapHistoryErr: false,
    indexSwapPredictionStarted: false,
    indexSwapPrediction: false,
    indexSwapPredictionErr: false,
    newChangeRiskTypeStarted: false,
    newChangeRiskType: false,
    newChangeRiskTypeErr: false,
    botInvestStatusStarted: false,
    botInvestStatus: false,
    botInvestStatusErr: false,
    newOpenBotStarted: false,
    newOpenBot: false,
    newOpenBotErr: false,
    botControlStarted: false,
    botControl: false,
    botControlErr: false,
    botReinvestStarted: false,
    botReinvest: false,
    botReinvestErr: false,
    botHistoryStarted: false,
    botHistory: false,
    botHistoryErr: false,
    standardWalletCryptoModalStatus: {status: false, item: false},
    walletIndicatorsStarted: false,
    walletIndicators: false,
    walletIndicatorsErr: false,
    cryptoNewsDataStarted: false,
    cryptoNewsData: false,
    cryptoNewsDataErr: false,
    createWalletAvailableCurrenciesStarted: false,
    createWalletAvailableCurrencies: false,
    createWalletAvailableCurrenciesErr: false,
    createWalletCurrencies: false,
    createFormStatus: false,
    createWalletStarted: false,
    createWallet: false,
    createWalletErr: false,
    walletAssetsStarted: false,
    walletAssets: false,
    walletAssetsWithBalance: false,
    refillableWallets: false,
    walletAssetsErr: false,
    currentWalletIndex: false,
    createCurrentWalletStarted: false,
    createCurrentWallet: false,
    createCurrentWalletErr: false,
    takeFormStatus: {status: false, data: false},
    sendFormStatus: {status: false, data: false},
    transferCommissionStarted: false,
    transferCommission: false,
    transferCommissionErr: false,
    sendTransactionsStatusStarted: false,
    sendTransactionsStatus: false,
    sendTransactionsStatusErr: false,
    walletGraphHistoryStarted: false,
    walletGraphHistory: false,
    walletGraphHistoryErr: false,
    omtPeriodsStarted: false,
    omtPeriods: false,
    omtPeriodsErr: false,
    availableWalletsStarted: false,
    availableWallets: false,
    availableWalletsErr: false,
    currentWalletStarted: false,
    currentWallet: false,
    currentWalletErr: false,
    currentWalletTransactionsStarted: false,
    currentWalletTransactions: false,
    currentWalletTransactionsErr: false,
    balanceTransactionStarted : false,
    balanceTransaction : false,
    balanceTransactionErr : false,
    oneCurrentWalletStarted: false,
    oneCurrentWallet: false,
    oneCurrentWalletErr: false,
    newOpenStakingStarted: false,
    newOpenStaking: false,
    newOpenStakingErr: false,
    availableStakingStarted: false,
    availableStaking: false,
    availableStakingErr: false,
    stakingCommissionStarted: false,
    stakingCommission: false,
    stakingCommissionErr: false,
    stakingAwardStarted: false,
    stakingAward: false,
    stakingAwardErr: false,
    stakingCloseCommissionStarted: false,
    stakingCloseCommission: false,
    stakingCloseCommissionErr: false,
    withdrawOmtStakingStarted: false,
    withdrawOmtStaking: false,
    withdrawOmtStakingErr: false,
    closeOmtStakingStarted: false,
    closeOmtStaking: false,
    closeOmtStakingErr: false,
    withdrawStakingStarted: false,
    withdrawStaking: false,
    withdrawStakingErr: false,
    exchangeStatusStarted: false,
    exchangeStatus: false,
    exchangeStatusErr: false,
    exchangeHistoryStarted: false,
    exchangeHistory: false,
    exchangeHistoryErr: false,
    exchangeDataStarted: false,
    exchangeData: false,
    exchangeDataErr: false,
    botModalStatus: false,
    botProPlusModalStatus: false,
    setCustomSettingsStarted: false,
    setCustomSettings: false,
    setCustomSettingsErr: false,
    buySubscribeStatusStarted: false,
    buySubscribeStatus: false,
    buySubscribeStatusErr: false,
    botProfitStarted: false,
    botProfit: false,
    botProfitErr: false,
    subscribeCommissionStarted: false,
    subscribeCommission: false,
    subscribeCommissionErr: false,
    setGreetingStatusStarted: false,
    setGreetingStatus: false,
    setGreetingStatusErr: false,
    exchangeCommissionStarted: false,
    exchangeCommission: false,
    exchangeCommissionErr: false,
    omtTokenStarted: false,
    omtToken: false,
    omtTokenErr: false,
    freezeTokenStatusStarted: false,
    freezeTokenStatus: false,
    freezeTokenStatusErr: false,
    freezeCommissionStarted: false,
    freezeCommission: false,
    freezeCommissionErr: false,
    activeFreezeStarted: false,
    activeFreeze: false,
    activeFreezeErr: false,
    setFreezeReinvestStarted: false,
    setFreezeReinvest: false,
    setFreezeReinvestErr: false,
    freezeWithdrawStatusStarted: false,
    freezeWithdrawStatus: false,
    freezeWithdrawStatusErr: false,
    freezeTotalStarted: false,
    freezeTotal: false,
    freezeTotalErr: false,
    clearIndexListStatus: false,
    swapMainDataStarted: false,
    swapMainData: false,
    swapMainDataErr: false,
    swapCommissionStarted: false,
    swapCommission: false,
    swapCommissionErr: false,
    createBuySwapRequestStatusStarted: false,
    createBuySwapRequestStatus: false,
    createBuySwapRequestStatusErr: false,
    createSellSwapRequestStatusStarted: false,
    createSellSwapRequestStatus: false,
    createSellSwapRequestStatusErr: false,
    mySwapRequestsStarted: false,
    mySwapRequests: false,
    mySwapRequestsErr: false,
    allSwapRequestsStarted: false,
    allSwapRequests: false,
    allSwapRequestsErr: false,
    cancelMySwapRequestsStarted: false,
    cancelMySwapRequests: false,
    cancelMySwapRequestsErr: false,
    clearMySwapRequestListStatus: false,
    clearAllSwapRequestListStatus: false,
    confirmBuySwapRequestStatusStarted: false,
    confirmBuySwapRequestStatus: false,
    confirmBuySwapRequestStatusErr: false,
    confirmSellSwapRequestStatusStarted: false,
    confirmSellSwapRequestStatus: false,
    confirmSellSwapRequestStatusErr: false,
    promotionsNyMainDataStarted: false,
    promotionsNyMainData: false,
    promotionsNyMainDataErr: false,
};

export function cabinetReducer(state = initialState, action) {
    switch (action.type) {
        case TEST:
            return {
                ...state,
                test: action.payload
            };
        case SET_MOBILE_NAV_STATUS:
            return {
                ...state,
                mobileNavStatus: action.payload
            };
        case CLEAR_INDEX_LIST:
            return {
                ...state,
                clearIndexListStatus: action.payload
            };
        case SET_BONUSES_MODAL_STATUS:
            return {
                ...state,
                bonusesModalStatus: action.payload
            };
        case SET_EVENT_NY_TASK_MODAL_STATUS:
            return {
                ...state,
                eventNyTaskModalStatus: action.payload
            };
        case SET_AMBASSADORS_MODAL_STATUS:
            return {
                ...state,
                ambassadorsModalStatus: action.payload
            };
        case SET_INFLUENCER_MODAL_STATUS:
            return {
                ...state,
                influencerModalStatus: action.payload
            };
        case SET_HONEECHIZE_AFFILIATE_SETTINGS_MODAL_STATUS:
            return {
                ...state,
                honeechizeAffiliateSettingsModalStatus: action.payload
            };
        case SET_HONEECHIZE_AFFILIATE_TABLE_MODAL_STATUS:
            return {
                ...state,
                honeechizeAffiliateTableModalStatus: action.payload
            };
        case SET_HONEECHIZE_ALERT_MODAL_STATUS:
            return {
                ...state,
                honeechizeAlertModalStatus: action.payload
            };
        case GET_BOT_MAIN_DATA_STARTED:
            return {
                ...state,
                botMainDataStarted: true
            };
        case GET_BOT_MAIN_DATA_SUCCESS:
            return {
                ...state,
                botMainData: action.payload,
                botMainDataStarted: false
            };
        case GET_BOT_MAIN_DATA_FAILURE:
            return {
                ...state,
                botMainDataErr: action.payload,
                botMainDataStarted: false
            };
        case SEND_SUPPORT_FORM_STARTED:
            return {
                ...state,
                supportFormStarted: true
            };
        case SEND_SUPPORT_FORM_SUCCESS:
            return {
                ...state,
                supportForm: action.payload,
                supportFormStarted: false
            };
        case SEND_SUPPORT_FORM_FAILURE:
            return {
                ...state,
                supportFormErr: action.payload,
                supportFormStarted: false
            };
        case GET_BOT_SUBSCRIBE_INFO_STARTED:
            return {
                ...state,
                botSubscribeInfoStarted: true
            };
        case GET_BOT_SUBSCRIBE_INFO_SUCCESS:
            return {
                ...state,
                botSubscribeInfo: action.payload,
                botSubscribeInfoStarted: false
            };
        case GET_BOT_SUBSCRIBE_INFO_FAILURE:
            return {
                ...state,
                botSubscribeInfoErr: action.payload,
                botSubscribeInfoStarted: false
            };
        case SET_BOT_SUBSCRIBE_MODAL_STATUS:
            return {
                ...state,
                botSubscribeModalStatus: action.payload
            };
        case SET_INDEX_FREEZE_MODAL_STATUS:
            return {
                ...state,
                indexFreezeModalStatus: action.payload
            };
        case SET_AMBASSADORS_MODAL_SALARY_STATUS:
            return {
                ...state,
                ambassadorsModalSalaryStatus: action.payload
            };
        case GET_REF_MAIN_DATA_STARTED:
            return {
                ...state,
                refMainDataStarted: true
            };
        case GET_REF_MAIN_DATA_SUCCESS:
            return {
                ...state,
                refMainData: action.payload,
                refMainDataStarted: false
            };
        case GET_REF_MAIN_DATA_FAILURE:
            return {
                ...state,
                refMainDataErr: action.payload,
                refMainDataStarted: false
            };
        case GET_REF_INFO_STARTED:
            return {
                ...state,
                refInfoStarted: true
            };
        case GET_REF_INFO_SUCCESS:
            return {
                ...state,
                refInfo: action.payload,
                refInfoStarted: false
            };
        case GET_REF_INFO_FAILURE:
            return {
                ...state,
                refInfoErr: action.payload,
                refInfoStarted: false
            };
        case GET_REF_LOGS_STARTED:
            return {
                ...state,
                refLogsStarted: true
            };
        case GET_REF_LOGS_SUCCESS:
            return {
                ...state,
                refLogs: action.payload,
                refLogsStarted: false
            };
        case GET_REF_LOGS_FAILURE:
            return {
                ...state,
                refLogsErr: action.payload,
                refLogsStarted: false
            };
        case SET_REF_LOGS_CORRECT_DATA:
            if (state.refLogsCorrectData && action.payload?.pageStatus){
                let paginateData = {
                    all: [...state.refLogsCorrectData.all, ...action.payload.all],
                    bot: [...state.refLogsCorrectData.bot, ...action.payload.bot],
                    staking: [...state.refLogsCorrectData.staking, ...action.payload.staking],
                    pageStatus: action.payload?.pageStatus
                }
                if (action.payload?.pageStatus !== state.refLogsCorrectData.pageStatus){
                    return {
                        ...state,
                        refLogsCorrectData: paginateData
                    };
                } else {
                    break;
                }
            } else {
                return {
                    ...state,
                    refLogsCorrectData: action.payload
                };
            }
        case SET_REF_LOGS_PAGE_DATA:
            return {
                ...state,
                refLogsPageData: action.payload
            };
        case GET_AMBASSADOR_SALARY_DATA_STARTED:
            return {
                ...state,
                ambassadorsSalaryDataStarted: true
            };
        case GET_AMBASSADOR_SALARY_DATA_SUCCESS:
            return {
                ...state,
                ambassadorsSalaryData: action.payload,
                ambassadorsSalaryDataStarted: false
            };
        case GET_AMBASSADOR_SALARY_DATA_FAILURE:
            return {
                ...state,
                ambassadorsSalaryDataErr: action.payload,
                ambassadorsSalaryDataStarted: false
            };
        case GET_AMBASSADOR_MAIN_DATA_STARTED:
            return {
                ...state,
                ambassadorMainDataStarted: true
            };
        case GET_AMBASSADOR_MAIN_DATA_SUCCESS:
            return {
                ...state,
                ambassadorMainData: action.payload,
                ambassadorMainDataStarted: false
            };
        case GET_AMBASSADOR_MAIN_DATA_FAILURE:
            return {
                ...state,
                ambassadorMainDataErr: action.payload,
                ambassadorMainDataStarted: false
            };
        case GET_AMBASSADOR_STATUSES_STARTED:
            return {
                ...state,
                ambassadorStatusesStarted: true
            };
        case GET_AMBASSADOR_STATUSES_SUCCESS:
            return {
                ...state,
                ambassadorStatuses: action.payload,
                ambassadorStatusesStarted: false
            };
        case GET_AMBASSADOR_STATUSES_FAILURE:
            return {
                ...state,
                ambassadorStatusesErr: action.payload,
                ambassadorStatusesStarted: false
            };
        case GET_AMBASSADOR_GRAPH_STARTED:
            return {
                ...state,
                ambassadorGraphStarted: true
            };
        case GET_AMBASSADOR_GRAPH_SUCCESS:
            return {
                ...state,
                ambassadorGraph: action.payload,
                ambassadorGraphStarted: false
            };
        case GET_AMBASSADOR_GRAPH_FAILURE:
            return {
                ...state,
                ambassadorGraphErr: action.payload,
                ambassadorGraphStarted: false
            };
        case GET_AMBASSADOR_GRAPH_CORRECT_DATA:
            return {
                ...state,
                ambassadorGraphCorrectData: action.payload,
            };
        case GET_AMBASSADOR_LINKS_STARTED:
            return {
                ...state,
                ambassadorLinksStarted: true
            };
        case GET_AMBASSADOR_LINKS_SUCCESS:
            return {
                ...state,
                ambassadorLinks: action.payload,
                ambassadorLinksStarted: false
            };
        case GET_AMBASSADOR_LINKS_FAILURE:
            return {
                ...state,
                ambassadorLinksErr: action.payload,
                ambassadorLinksStarted: false
            };
        case SEND_AMBASSADOR_LINK_STARTED:
            return {
                ...state,
                sendAmbassadorLinkStarted: true
            };
        case SEND_AMBASSADOR_LINK_SUCCESS:
            return {
                ...state,
                sendAmbassadorLink: action.payload,
                sendAmbassadorLinkStarted: false
            };
        case SEND_AMBASSADOR_LINK_FAILURE:
            return {
                ...state,
                sendAmbassadorLinkErr: action.payload,
                sendAmbassadorLinkStarted: false
            };
        case GET_AMBASSADOR_TURNOVER_STARTED:
            return {
                ...state,
                ambassadorTurnoverStarted: true
            };
        case GET_AMBASSADOR_TURNOVER_SUCCESS:
            return {
                ...state,
                ambassadorTurnover: action.payload,
                ambassadorTurnoverStarted: false
            };
        case GET_AMBASSADOR_TURNOVER_FAILURE:
            return {
                ...state,
                ambassadorTurnoverErr: action.payload,
                ambassadorTurnoverStarted: false
            };
        case AMBASSADOR_LINK_ERRORS:
            let newErr = state.ambassadorLinksErrors;
            if (action.payload.index){
                newErr[action.payload.type] = {err: action.payload.err, index: action.payload.index}
            } else {
                newErr[action.payload.type] = action.payload.err
            }
            return {
                ...state,
                ambassadorLinksErrors: {...newErr}
            };
        case AMBASSADOR_LINK_ERRORS_CLEAR:
            let clearLink = state.ambassadorLinksErrors;
            delete clearLink[action.payload]
            return {
                ...state,
                ambassadorLinksErrors: {...clearLink}
            };
        case AMBASSADOR_CORRECT_LINKS:
            return {
                ...state,
                ambassadorCorrectLinks: action.payload
            };
        case CHANGE_PASSWORD_STARTED:
            return {
                ...state,
                changePasswordStatusStarted: true
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordStatus: action.payload,
                changePasswordStatusStarted: false
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordStatusErr: action.payload,
                changePasswordStatusStarted: false
            };
        case SET_HONEECHIZE_AFFILIATE_STATISTIC_CORRECT_DATA:
            if (state.honeechizeAffiliateStatisticCorrectData && action.payload?.pageStatus){
                let paginateData = {
                    day: [...state.honeechizeAffiliateStatisticCorrectData.day, ...action.payload.day],
                    week: [...state.honeechizeAffiliateStatisticCorrectData.week, ...action.payload.week],
                    month: [...state.honeechizeAffiliateStatisticCorrectData.month, ...action.payload.month],
                    all: [...state.honeechizeAffiliateStatisticCorrectData.all, ...action.payload.all],
                    pageStatus: action.payload?.pageStatus
                }

                if (action.payload?.pageStatus !== state.honeechizeAffiliateStatisticCorrectData.pageStatus){
                    return {
                        ...state,
                        honeechizeAffiliateStatisticCorrectData: paginateData
                    };
                } else {
                    break;
                }
            } else {
                return {
                    ...state,
                    honeechizeAffiliateStatisticCorrectData: action.payload
                };
            }
        case SET_HONEECHIZE_AFFILIATE_STATISTIC_PAGE_DATA:
            return {
                ...state,
                honeechizeAffiliateStatisticPageData: action.payload
            };
        case GET_HONEECHIZE_MAIN_DATA_STARTED:
            return {
                ...state,
                honeechizeMainDataStarted: true
            };
        case GET_HONEECHIZE_MAIN_DATA_SUCCESS:
            return {
                ...state,
                honeechizeMainData: action.payload,
                honeechizeMainDataStarted: false
            };
        case GET_HONEECHIZE_MAIN_DATA_FAILURE:
            return {
                ...state,
                honeechizeMainDataErr: action.payload,
                honeechizeMainDataStarted: false
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_STARTED:
            return {
                ...state,
                honeechizeEarningsGraphDataStarted: true
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                honeechizeEarningsGraphData: action.payload,
                honeechizeEarningsGraphDataStarted: false
            };
        case GET_HONEECHIZE_EARNINGS_GRAPH_DATA_FAILURE:
            return {
                ...state,
                honeechizeEarningsGraphDataErr: action.payload,
                honeechizeEarningsGraphDataStarted: false
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_STARTED:
            return {
                ...state,
                honeechizeProfitGraphDataStarted: true
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_SUCCESS:
            return {
                ...state,
                honeechizeProfitGraphData: action.payload,
                honeechizeProfitGraphDataStarted: false
            };
        case GET_HONEECHIZE_PROFIT_GRAPH_DATA_FAILURE:
            return {
                ...state,
                honeechizeProfitGraphDataErr: action.payload,
                honeechizeProfitGraphDataStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_STARTED:
            return {
                ...state,
                honeechizeAffiliateSettingsStarted: true
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_SUCCESS:
            return {
                ...state,
                honeechizeAffiliateSettings: action.payload,
                honeechizeAffiliateSettingsStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_SETTINGS_FAILURE:
            return {
                ...state,
                honeechizeAffiliateSettingsErr: action.payload,
                honeechizeAffiliateSettingsStarted: false
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_STARTED:
            return {
                ...state,
                changeHoneechizeAffiliatePercentStarted: true
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_SUCCESS:
            return {
                ...state,
                changeHoneechizeAffiliatePercent: action.payload,
                changeHoneechizeAffiliatePercentStarted: false
            };
        case CHANGE_HONEECHIZE_AFFILIATE_PERCENT_FAILURE:
            return {
                ...state,
                changeHoneechizeAffiliatePercentErr: action.payload,
                changeHoneechizeAffiliatePercentStarted: false
            };
        case GET_HONEECHIZE_BOT_EARNINGS_STARTED:
            return {
                ...state,
                honeechizeBotEarningsDataStarted: true
            };
        case GET_HONEECHIZE_BOT_EARNINGS_SUCCESS:
            return {
                ...state,
                honeechizeBotEarningsData: action.payload,
                honeechizeBotEarningsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_EARNINGS_FAILURE:
            return {
                ...state,
                honeechizeBotEarningsDataErr: action.payload,
                honeechizeBotEarningsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_SETTINGS_STARTED:
            return {
                ...state,
                honeechizeBotSettingsDataStarted: true
            };
        case GET_HONEECHIZE_BOT_SETTINGS_SUCCESS:
            return {
                ...state,
                honeechizeBotSettingsData: action.payload,
                honeechizeBotSettingsDataStarted: false
            };
        case GET_HONEECHIZE_BOT_SETTINGS_FAILURE:
            return {
                ...state,
                honeechizeBotSettingsDataErr: action.payload,
                honeechizeBotSettingsDataStarted: false
            };
        case SET_HONEECHIZE_BOT_SETTINGS_STARTED:
            return {
                ...state,
                setHoneechizeBotSettingsDataStarted: true
            };
        case SET_HONEECHIZE_BOT_SETTINGS_SUCCESS:
            return {
                ...state,
                setHoneechizeBotSettingsData: action.payload,
                setHoneechizeBotSettingsDataStarted: false
            };
        case SET_HONEECHIZE_BOT_SETTINGS_FAILURE:
            return {
                ...state,
                setHoneechizeBotSettingsDataErr: action.payload,
                setHoneechizeBotSettingsDataStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_STARTED:
            return {
                ...state,
                honeechizeAffiliateStatisticStarted: true
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_SUCCESS:
            return {
                ...state,
                honeechizeAffiliateStatistic: action.payload,
                honeechizeAffiliateStatisticStarted: false
            };
        case GET_HONEECHIZE_AFFILIATE_STATISTIC_FAILURE:
            return {
                ...state,
                honeechizeAffiliateStatisticErr: action.payload,
                honeechizeAffiliateStatisticStarted: false
            };
        case GET_CUSTOM_SETTINGS_STARTED:
            return {
                ...state,
                customSettingsStarted: true
            };
        case GET_CUSTOM_SETTINGS_SUCCESS:
            return {
                ...state,
                customSettings: action.payload,
                customSettingsStarted: false
            };
        case GET_CUSTOM_SETTINGS_FAILURE:
            return {
                ...state,
                customSettingsErr: action.payload,
                customSettingsStarted: false
            };
        case GET_INDEX_MAIN_DATA_STARTED:
            return {
                ...state,
                indexMainDataStarted: true
            };
        case GET_INDEX_MAIN_DATA_SUCCESS:
            return {
                ...state,
                indexMainData: action.payload,
                indexMainDataStarted: false
            };
        case GET_INDEX_MAIN_DATA_FAILURE:
            return {
                ...state,
                indexMainDataErr: action.payload,
                indexMainDataStarted: false
            };
        case INDEX_SWAP_STARTED:
            return {
                ...state,
                indexSwapStatusStarted: true
            };
        case INDEX_SWAP_SUCCESS:
            return {
                ...state,
                indexSwapStatus: action.payload,
                indexSwapStatusStarted: false
            };
        case INDEX_SWAP_FAILURE:
            return {
                ...state,
                indexSwapStatusErr: action.payload,
                indexSwapStatusStarted: false
            };
        case GET_INDEX_SWAP_HISTORY_STARTED:
            return {
                ...state,
                indexSwapHistoryStarted: true
            };
        case GET_INDEX_SWAP_HISTORY_SUCCESS:
            return {
                ...state,
                indexSwapHistory: action.payload,
                indexSwapHistoryStarted: false
            };
        case GET_INDEX_SWAP_HISTORY_FAILURE:
            return {
                ...state,
                indexSwapHistoryErr: action.payload,
                indexSwapHistoryStarted: false
            };
        case GET_INDEX_SWAP_PREDICTION_STARTED:
            return {
                ...state,
                indexSwapPredictionStarted: true
            };
        case GET_INDEX_SWAP_PREDICTION_SUCCESS:
            return {
                ...state,
                indexSwapPrediction: action.payload,
                indexSwapPredictionStarted: false
            };
        case GET_INDEX_SWAP_PREDICTION_FAILURE:
            return {
                ...state,
                indexSwapPredictionErr: action.payload,
                indexSwapPredictionStarted: false
            };
        case CHANGE_BOT_RISK_STARTED:
            return {
                ...state,
                newChangeRiskTypeStarted: true
            };
        case CHANGE_BOT_RISK_SUCCESS:
            return {
                ...state,
                newChangeRiskType: action.payload,
                newChangeRiskTypeStarted: false
            };
        case CHANGE_BOT_RISK_FAILURE:
            return {
                ...state,
                newChangeRiskTypeErr: action.payload,
                newChangeRiskTypeStarted: false
            };
        case SET_BOT_PROMO_MODAL_STATUS:
            return {
                ...state,
                botPromoModalStatus: action.payload
            };
        case BOT_INVEST_STARTED:
            return {
                ...state,
                botInvestStatusStarted: true
            };
        case BOT_INVEST_SUCCESS:
            return {
                ...state,
                botInvestStatus: action.payload,
                botInvestStatusStarted: false
            };
        case BOT_INVEST_FAILURE:
            return {
                ...state,
                botInvestStatusErr: action.payload,
                botInvestStatusStarted: false
            };
        case OPEN_BOT_STARTED:
            return {
                ...state,
                newOpenBotStarted: true
            };
        case OPEN_BOT_SUCCESS:
            return {
                ...state,
                newOpenBot: action.payload,
                newOpenBotStarted: false
            };
        case OPEN_BOT_FAILURE:
            return {
                ...state,
                newOpenBotErr: action.payload,
                newOpenBotStarted: false
            };
        case CONTROL_BOT_STARTED:
            return {
                ...state,
                botControlStarted: true
            };
        case CONTROL_BOT_SUCCESS:
            return {
                ...state,
                botControl: action.payload,
                botControlStarted: false
            };
        case CONTROL_BOT_FAILURE:
            return {
                ...state,
                botControlErr: action.payload,
                botControlStarted: false
            };
        case SET_BOT_REINVEST_STARTED:
            return {
                ...state,
                botReinvestStarted: true
            };
        case SET_BOT_REINVEST_SUCCESS:
            return {
                ...state,
                botReinvest: action.payload,
                botReinvestStarted: false
            };
        case SET_BOT_REINVEST_FAILURE:
            return {
                ...state,
                botReinvestErr: action.payload,
                botReinvestStarted: false
            };
        case GET_BOT_HISTORY_STARTED:
            return {
                ...state,
                botHistoryStarted: true
            };
        case GET_BOT_HISTORY_SUCCESS:
            return {
                ...state,
                botHistory: action.payload,
                botHistoryStarted: false
            };
        case GET_BOT_HISTORY_FAILURE:
            return {
                ...state,
                botHistoryErr: action.payload,
                botHistoryStarted: false
            };
        case SET_STANDARD_WALLET_CRYPTO_MODAL_STATUS:
            return {
                ...state,
                standardWalletCryptoModalStatus: action.payload
            };
        case GET_INDICATORS_STARTED:
            return {
                ...state,
                walletIndicatorsStarted: true
            };
        case GET_INDICATORS_SUCCESS:
            return {
                ...state,
                walletIndicators: action.payload,
                walletIndicatorsStarted: false
            };
        case GET_INDICATORS_FAILURE:
            return {
                ...state,
                walletIndicatorsErr: action.payload,
                walletIndicatorsStarted: false
            };
        case GET_CRYPTO_NEWS_STARTED:
            return {
                ...state,
                cryptoNewsDataStarted: true
            };
        case GET_CRYPTO_NEWS_SUCCESS:
            return {
                ...state,
                cryptoNewsData: action.payload,
                cryptoNewsDataStarted: false
            };
        case GET_CRYPTO_NEWS_FAILURE:
            return {
                ...state,
                cryptoNewsDataErr: action.payload,
                cryptoNewsDataStarted: false
            };
        case GET_AVAILABLE_CURRENCIES_STARTED:
            return {
                ...state,
                createWalletAvailableCurrenciesStarted: true
            };
        case GET_AVAILABLE_CURRENCIES_SUCCESS:
            return {
                ...state,
                createWalletAvailableCurrencies: action.payload,
                createWalletAvailableCurrenciesStarted: false
            };
        case GET_AVAILABLE_CURRENCIES_FAILURE:
            return {
                ...state,
                createWalletAvailableCurrenciesErr: action.payload,
                createWalletAvailableCurrenciesStarted: false
            };
        case SET_CREATE_WALLET_CURRENCIES:
            return {
                ...state,
                createWalletCurrencies: action.payload
            };
        case SET_WALLET_CREATE_FORM_STATUS:
            return {
                ...state,
                createFormStatus: action.payload
            };
        case CREATE_WALLET_STARTED:
            return {
                ...state,
                createWalletStarted: true
            };
        case CREATE_WALLET_SUCCESS:
            return {
                ...state,
                createWallet: action.payload,
                createWalletStarted: false
            };
        case CREATE_WALLET_FAILURE:
            return {
                ...state,
                createWalletErr: action.payload,
                createWalletStarted: false
            };
        case GET_WALLET_ASSETS_STARTED:
            return {
                ...state,
                walletAssetsStarted: true
            };
        case GET_WALLET_ASSETS_SUCCESS:
            return {
                ...state,
                walletAssets: action.payload,
                walletAssetsStarted: false
            };
        case GET_WALLET_ASSETS_FAILURE:
            return {
                ...state,
                walletAssetsErr: action.payload,
                walletAssetsStarted: false
            };
        case GET_WALLET_ASSETS_WITH_BALANCE:
            return {
                ...state,
                walletAssetsWithBalance: action.payload
            };
        case GET_REFILLABLE_WALLETS:
            return {
                ...state,
                refillableWallets: action.payload
            };
        case SET_CURRENT_WALLET:
            return {
                ...state,
                currentWalletIndex: action.payload
            };
        case CREATE_CURRENT_WALLET_STARTED:
            return {
                ...state,
                createCurrentWalletStarted: true
            };
        case CREATE_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                createCurrentWallet: action.payload,
                createCurrentWalletStarted: false
            };
        case CREATE_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                createCurrentWalletErr: action.payload,
                createCurrentWalletStarted: false
            };
        case SET_WALLET_TAKE_FORM_STATUS:
            return {
                ...state,
                takeFormStatus: action.payload
            };
        case SET_WALLET_SEND_FORM_STATUS:
            return {
                ...state,
                sendFormStatus: action.payload
            };
        case GET_TRANSFER_COMMISSION_STARTED:
            return {
                ...state,
                transferCommissionStarted: true
            };
        case GET_TRANSFER_COMMISSION_SUCCESS:
            return {
                ...state,
                transferCommission: action.payload,
                transferCommissionStarted: false
            };
        case GET_TRANSFER_COMMISSION_FAILURE:
            return {
                ...state,
                transferCommissionErr: action.payload,
                transferCommissionStarted: false
            };
        case SEND_TRANSACTIONS_STARTED:
            return {
                ...state,
                sendTransactionsStatusStarted: true
            };
        case SEND_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                sendTransactionsStatus: action.payload,
                sendTransactionsStatusStarted: false
            };
        case SEND_TRANSACTIONS_FAILURE:
            return {
                ...state,
                sendTransactionsStatusErr: action.payload,
                sendTransactionsStatusStarted: false
            };
        case GET_WALLET_GRAPH_HISTORY_STARTED:
            return {
                ...state,
                walletGraphHistoryStarted: true
            };
        case GET_WALLET_GRAPH_HISTORY_SUCCESS:
            return {
                ...state,
                walletGraphHistory: action.payload,
                walletGraphHistoryStarted: false
            };
        case GET_WALLET_GRAPH_HISTORY_FAILURE:
            return {
                ...state,
                walletGraphHistoryErr: action.payload,
                walletGraphHistoryStarted: false
            };
        case GET_OMT_PERIODS_STARTED:
            return {
                ...state,
                omtPeriodsStarted: true
            };
        case GET_OMT_PERIODS_SUCCESS:
            return {
                ...state,
                omtPeriods: action.payload,
                omtPeriodsStarted: false
            };
        case GET_OMT_PERIODS_FAILURE:
            return {
                ...state,
                omtPeriodsErr: action.payload,
                omtPeriodsStarted: false
            };
        case GET_AVAILABLE_WALLETS_STARTED:
            return {
                ...state,
                availableWalletsStarted: true
            };
        case GET_AVAILABLE_WALLETS_SUCCESS:
            return {
                ...state,
                availableWallets: action.payload,
                availableWalletsStarted: false
            };
        case GET_AVAILABLE_WALLETS_FAILURE:
            return {
                ...state,
                availableWalletsErr: action.payload,
                availableWalletsStarted: false
            };
        case GET_CURRENT_WALLET_STARTED:
            return {
                ...state,
                currentWalletStarted: true
            };
        case GET_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                currentWallet: action.payload,
                currentWalletStarted: false
            };
        case GET_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                currentWalletErr: action.payload,
                currentWalletStarted: false
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_STARTED:
            return {
                ...state,
                currentWalletTransactionsStarted: true
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                currentWalletTransactions: action.payload,
                currentWalletTransactionsStarted: false
            };
        case GET_CURRENT_WALLET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                currentWalletTransactionsErr: action.payload,
                currentWalletTransactionsStarted: false
            };
        case SET_STAKING_FORM_STATUS:
            return {
                ...state,
                stakingFormStatus: action.payload
            };
        case BALANCE_TRANSACTIONS_STARTED:
            return {
                ...state,
                balanceTransactionStarted: true
            };
        case BALANCE_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                balanceTransaction: action.payload,
                balanceTransactionStarted: false
            };
        case BALANCE_TRANSACTIONS_FAILURE:
            return {
                ...state,
                balanceTransactionErr: action.payload,
                balanceTransactionStarted: false
            };
        case SET_STAKING_BALANCE_STATUS:
            return {
                ...state,
                stakingBalanceStatus: action.payload
            };
        case GET_ONE_CURRENT_WALLET_STARTED:
            return {
                ...state,
                oneCurrentWalletStarted: true
            };
        case GET_ONE_CURRENT_WALLET_SUCCESS:
            return {
                ...state,
                oneCurrentWallet: action.payload,
                oneCurrentWalletStarted: false
            };
        case GET_ONE_CURRENT_WALLET_FAILURE:
            return {
                ...state,
                oneCurrentWalletErr: action.payload,
                oneCurrentWalletStarted: false
            };
        case OPEN_STAKING_STARTED:
            return {
                ...state,
                newOpenStakingStarted: true
            };
        case OPEN_STAKING_SUCCESS:
            return {
                ...state,
                newOpenStaking: action.payload,
                newOpenStakingStarted: false
            };
        case OPEN_STAKING_FAILURE:
            return {
                ...state,
                newOpenStakingErr: action.payload,
                newOpenStakingStarted: false
            };
        case GET_AVAILABLE_STAKING_STARTED:
            return {
                ...state,
                availableStakingStarted: true
            };
        case GET_AVAILABLE_STAKING_SUCCESS:
            return {
                ...state,
                availableStaking: action.payload,
                availableStakingStarted: false
            };
        case GET_AVAILABLE_STAKING_FAILURE:
            return {
                ...state,
                availableStakingErr: action.payload,
                availableStakingStarted: false
            };
        case SET_STAKING_FORM_OMT_STATUS:
            return {
                ...state,
                stakingFormOmtStatus: action.payload
            };
        case GET_STAKING_COMMISSION_STARTED:
            return {
                ...state,
                stakingCommissionStarted: true
            };
        case GET_STAKING_COMMISSION_SUCCESS:
            return {
                ...state,
                stakingCommission: action.payload,
                stakingCommissionStarted: false
            };
        case GET_STAKING_COMMISSION_FAILURE:
            return {
                ...state,
                stakingCommissionErr: action.payload,
                stakingCommissionStarted: false
            };
        case SET_STAKING_WITHDRAW_FORM_STATUS:
            return {
                ...state,
                stakingWithdrawFormStatus: action.payload
            };
        case SET_STAKING_CLOSE_FORM_STATUS:
            return {
                ...state,
                stakingCloseFormStatus: action.payload
            };
        case GET_STAKING_AWARD_STARTED:
            return {
                ...state,
                stakingAwardStarted: true
            };
        case GET_STAKING_AWARD_SUCCESS:
            return {
                ...state,
                stakingAward: action.payload,
                stakingAwardStarted: false
            };
        case GET_STAKING_AWARD_FAILURE:
            return {
                ...state,
                stakingAwardErr: action.payload,
                stakingAwardStarted: false
            };
        case GET_STAKING_CLOSE_COMMISSION_STARTED:
            return {
                ...state,
                stakingCommissionStarted: true
            };
        case GET_STAKING_CLOSE_COMMISSION_SUCCESS:
            return {
                ...state,
                stakingCloseCommission: action.payload,
                stakingCommissionStarted: false
            };
        case GET_STAKING_CLOSE_COMMISSION_FAILURE:
            return {
                ...state,
                stakingCloseCommissionErr: action.payload,
                stakingCommissionStarted: false
            };
        case WITHDRAW_OMT_STAKING_STARTED:
            return {
                ...state,
                withdrawOmtStakingStarted: true
            };
        case WITHDRAW_OMT_STAKING_SUCCESS:
            return {
                ...state,
                withdrawOmtStaking: action.payload,
                withdrawOmtStakingStarted: false
            };
        case WITHDRAW_OMT_STAKING_FAILURE:
            return {
                ...state,
                withdrawOmtStakingErr: action.payload,
                withdrawOmtStakingStarted: false
            };
        case CLOSE_OMT_STAKING_STARTED:
            return {
                ...state,
                closeOmtStakingStarted: true
            };
        case CLOSE_OMT_STAKING_SUCCESS:
            return {
                ...state,
                closeOmtStaking: action.payload,
                closeOmtStakingStarted: false
            };
        case CLOSE_OMT_STAKING_FAILURE:
            return {
                ...state,
                closeOmtStakingErr: action.payload,
                closeOmtStakingStarted: false
            };
        case WITHDRAW_STAKING_STARTED:
            return {
                ...state,
                withdrawStakingStarted: true
            };
        case WITHDRAW_STAKING_SUCCESS:
            return {
                ...state,
                withdrawStaking: action.payload,
                withdrawStakingStarted: false
            };
        case WITHDRAW_STAKING_FAILURE:
            return {
                ...state,
                withdrawStakingErr: action.payload,
                withdrawStakingStarted: false
            };
        case EXCHANGE_STARTED:
            return {
                ...state,
                exchangeStatusStarted: true
            };
        case EXCHANGE_SUCCESS:
            return {
                ...state,
                exchangeStatus: action.payload,
                exchangeStatusStarted: false
            };
        case EXCHANGE_FAILURE:
            return {
                ...state,
                exchangeStatusErr: action.payload,
                exchangeStatusStarted: false
            };
        case GET_EXCHANGE_HISTORY_STARTED:
            return {
                ...state,
                exchangeHistoryStarted: true
            };
        case GET_EXCHANGE_HISTORY_SUCCESS:
            return {
                ...state,
                exchangeHistory: action.payload,
                exchangeHistoryStarted: false
            };
        case GET_EXCHANGE_HISTORY_FAILURE:
            return {
                ...state,
                exchangeHistoryErr: action.payload,
                exchangeHistoryStarted: false
            };
        case GET_EXCHANGE_DATA_STARTED:
            return {
                ...state,
                exchangeDataStarted: true
            };
        case GET_EXCHANGE_DATA_SUCCESS:
            return {
                ...state,
                exchangeData: action.payload,
                exchangeDataStarted: false
            };
        case GET_EXCHANGE_DATA_FAILURE:
            return {
                ...state,
                exchangeDataErr: action.payload,
                exchangeDataStarted: false
            };
        case GET_WALLET_TRANSACTIONS_STARTED:
            return {
                ...state,
                walletTransactionsStarted: true
            };
        case GET_WALLET_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                walletTransactions: action.payload,
                walletTransactionsStarted: false
            };
        case GET_WALLET_TRANSACTIONS_FAILURE:
            return {
                ...state,
                walletTransactionsErr: action.payload,
                walletTransactionsStarted: false
            };
        case SET_BOT_MODAL_STATUS:
            return {
                ...state,
                botModalStatus: action.payload
            };
        case SET_BOT_PRO_PLUS_MODAL_STATUS:
            return {
                ...state,
                botProPlusModalStatus: action.payload
            };
        case SET_CUSTOM_SETTINGS_STARTED:
            return {
                ...state,
                setCustomSettingsStarted: true
            };
        case SET_CUSTOM_SETTINGS_SUCCESS:
            return {
                ...state,
                setCustomSettings: action.payload,
                setCustomSettingsStarted: false
            };
        case SET_CUSTOM_SETTINGS_FAILURE:
            return {
                ...state,
                setCustomSettingsErr: action.payload,
                setCustomSettingsStarted: false
            };
        case BUY_SUBSCRIBE_STARTED:
            return {
                ...state,
                buySubscribeStatusStarted: true
            };
        case BUY_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                buySubscribeStatus: action.payload,
                buySubscribeStatusStarted: false
            };
        case BUY_SUBSCRIBE_FAILURE:
            return {
                ...state,
                buySubscribeStatusErr: action.payload,
                buySubscribeStatusStarted: false
            };
        case GET_BOT_PROFIT_STARTED:
            return {
                ...state,
                botProfitStarted: true
            };
        case GET_BOT_PROFIT_SUCCESS:
            return {
                ...state,
                botProfit: action.payload,
                botProfitStarted: false
            };
        case GET_BOT_PROFIT_FAILURE:
            return {
                ...state,
                botProfitErr: action.payload,
                botProfitStarted: false
            };
        case GET_SUBSCRIBE_COMMISSION_STARTED:
            return {
                ...state,
                subscribeCommissionStarted: true
            };
        case GET_SUBSCRIBE_COMMISSION_SUCCESS:
            return {
                ...state,
                subscribeCommission: action.payload,
                subscribeCommissionStarted: false
            };
        case GET_SUBSCRIBE_COMMISSION_FAILURE:
            return {
                ...state,
                subscribeCommissionErr: action.payload,
                subscribeCommissionStarted: false
            };
        case SET_GREETING_STARTED:
            return {
                ...state,
                setGreetingStatusStarted: true
            };
        case SET_GREETING_SUCCESS:
            return {
                ...state,
                setGreetingStatus: action.payload,
                setGreetingStatusStarted: false
            };
        case SET_GREETING_FAILURE:
            return {
                ...state,
                setGreetingStatusErr: action.payload,
                setGreetingStatusStarted: false
            };
        case GET_EXCHANGE_COMMISSION_STARTED:
            return {
                ...state,
                exchangeCommissionStarted: true
            };
        case GET_EXCHANGE_COMMISSION_SUCCESS:
            return {
                ...state,
                exchangeCommission: action.payload,
                exchangeCommissionStarted: false
            };
        case GET_EXCHANGE_COMMISSION_FAILURE:
            return {
                ...state,
                exchangeCommissionErr: action.payload,
                exchangeCommissionStarted: false
            };
        case GET_OMT_TOKEN_STARTED:
            return {
                ...state,
                omtTokenStarted: true
            };
        case GET_OMT_TOKEN_SUCCESS:
            return {
                ...state,
                omtToken: action.payload,
                omtTokenStarted: false
            };
        case GET_OMT_TOKEN_FAILURE:
            return {
                ...state,
                omtTokenErr: action.payload,
                omtTokenStarted: false
            };
        case FREEZE_TOKEN_STARTED:
            return {
                ...state,
                freezeTokenStatusStarted: true
            };
        case FREEZE_TOKEN_SUCCESS:
            return {
                ...state,
                freezeTokenStatus: action.payload,
                freezeTokenStatusStarted: false
            };
        case FREEZE_TOKEN_FAILURE:
            return {
                ...state,
                freezeTokenStatusErr: action.payload,
                freezeTokenStatusStarted: false
            };
        case GET_FREEZE_COMMISSION_STARTED:
            return {
                ...state,
                freezeCommissionStarted: true
            };
        case GET_FREEZE_COMMISSION_SUCCESS:
            return {
                ...state,
                freezeCommission: action.payload,
                freezeCommissionStarted: false
            };
        case GET_FREEZE_COMMISSION_FAILURE:
            return {
                ...state,
                freezeCommissionErr: action.payload,
                freezeCommissionStarted: false
            };
        case GET_ACTIVE_FREEZE_STARTED:
            return {
                ...state,
                activeFreezeStarted: true
            };
        case GET_ACTIVE_FREEZE_SUCCESS:
            return {
                ...state,
                activeFreeze: action.payload,
                activeFreezeStarted: false
            };
        case GET_ACTIVE_FREEZE_FAILURE:
            return {
                ...state,
                activeFreezeErr: action.payload,
                activeFreezeStarted: false
            };
        case SET_FREEZE_REINVEST_STARTED:
            return {
                ...state,
                setFreezeReinvestStarted: true
            };
        case SET_FREEZE_REINVEST_SUCCESS:
            return {
                ...state,
                setFreezeReinvest: action.payload,
                setFreezeReinvestStarted: false
            };
        case SET_FREEZE_REINVEST_FAILURE:
            return {
                ...state,
                setFreezeReinvestErr: action.payload,
                setFreezeReinvestStarted: false
            };
        case FREEZE_WITHDRAW_STARTED:
            return {
                ...state,
                freezeWithdrawStatusStarted: true
            };
        case FREEZE_WITHDRAW_SUCCESS:
            return {
                ...state,
                freezeWithdrawStatus: action.payload,
                freezeWithdrawStatusStarted: false
            };
        case FREEZE_WITHDRAW_FAILURE:
            return {
                ...state,
                freezeWithdrawStatusErr: action.payload,
                freezeWithdrawStatusStarted: false
            };
        case GET_FREEZE_TOTAL_STARTED:
            return {
                ...state,
                freezeTotalStarted: true
            };
        case GET_FREEZE_TOTAL_SUCCESS:
            return {
                ...state,
                freezeTotal: action.payload,
                freezeTotalStarted: false
            };
        case GET_FREEZE_TOTAL_FAILURE:
            return {
                ...state,
                freezeTotalErr: action.payload,
                freezeTotalStarted: false
            };
        case SET_SWAP_REQUEST_MODAL_STATUS:
            return {
                ...state,
                swapRequestModalStatus: action.payload
            };
        case SET_SWAP_REQUEST_CONFIRM_MODAL_STATUS:
            return {
                ...state,
                swapRequestConfirmModalStatus: action.payload
            };
        case SET_SWAP_REQUEST_CANCEL_MODAL_STATUS:
            return {
                ...state,
                swapRequestCancelModalStatus: action.payload
            };
        case GET_SWAP_MAIN_DATA_STARTED:
            return {
                ...state,
                swapMainDataStarted: true
            };
        case GET_SWAP_MAIN_DATA_SUCCESS:
            return {
                ...state,
                swapMainData: action.payload,
                swapMainDataStarted: false
            };
        case GET_SWAP_MAIN_DATA_FAILURE:
            return {
                ...state,
                swapMainDataErr: action.payload,
                swapMainDataStarted: false
            };
        case GET_SWAP_COMMISSION_STARTED:
            return {
                ...state,
                swapCommissionStarted: true
            };
        case GET_SWAP_COMMISSION_SUCCESS:
            return {
                ...state,
                swapCommission: action.payload,
                swapCommissionStarted: false
            };
        case GET_SWAP_COMMISSION_FAILURE:
            return {
                ...state,
                swapCommissionErr: action.payload,
                swapCommissionStarted: false
            };
        case CREATE_BUY_SWAP_REQUEST_STARTED:
            return {
                ...state,
                createBuySwapRequestStatusStarted: true
            };
        case CREATE_BUY_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                createBuySwapRequestStatus: action.payload,
                createBuySwapRequestStatusStarted: false
            };
        case CREATE_BUY_SWAP_REQUEST_FAILURE:
            return {
                ...state,
                createBuySwapRequestStatusErr: action.payload,
                createBuySwapRequestStatusStarted: false
            };
        case CREATE_SELL_SWAP_REQUEST_STARTED:
            return {
                ...state,
                createSellSwapRequestStatusStarted: true
            };
        case CREATE_SELL_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                createSellSwapRequestStatus: action.payload,
                createSellSwapRequestStatusStarted: false
            };
        case CREATE_SELL_SWAP_REQUEST_FAILURE:
            return {
                ...state,
                createSellSwapRequestStatusErr: action.payload,
                createSellSwapRequestStatusStarted: false
            };
        case GET_MY_SWAP_REQUESTS_STARTED:
            return {
                ...state,
                mySwapRequestsStarted: true
            };
        case GET_MY_SWAP_REQUESTS_SUCCESS:
            return {
                ...state,
                mySwapRequests: action.payload,
                mySwapRequestsStarted: false
            };
        case GET_MY_SWAP_REQUESTS_FAILURE:
            return {
                ...state,
                mySwapRequestsErr: action.payload,
                mySwapRequestsStarted: false
            };
        case CANCEL_MY_SWAP_REQUEST_STARTED:
            return {
                ...state,
                cancelMySwapRequestsStarted: true
            };
        case CANCEL_MY_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                cancelMySwapRequests: action.payload,
                cancelMySwapRequestsStarted: false
            };
        case CANCEL_MY_SWAP_REQUEST_FAILURE:
            return {
                ...state,
                cancelMySwapRequestsErr: action.payload,
                cancelMySwapRequestsStarted: false
            };
        case GET_ALL_SWAP_REQUESTS_STARTED:
            return {
                ...state,
                allSwapRequestsStarted: true
            };
        case GET_ALL_SWAP_REQUESTS_SUCCESS:
            return {
                ...state,
                allSwapRequests: action.payload,
                allSwapRequestsStarted: false
            };
        case GET_ALL_SWAP_REQUESTS_FAILURE:
            return {
                ...state,
                allSwapRequestsErr: action.payload,
                allSwapRequestsStarted: false
            };
        case CLEAR_MY_SWAP_REQUEST_LIST:
            return {
                ...state,
                clearMySwapRequestListStatus: action.payload
            };
        case CLEAR_ALL_SWAP_REQUEST_LIST:
            return {
                ...state,
                clearAllSwapRequestListStatus: action.payload
            };
        case CONFIRM_BUY_SWAP_REQUEST_STARTED:
            return {
                ...state,
                confirmBuySwapRequestStatusStarted: true
            };
        case CONFIRM_BUY_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                confirmBuySwapRequestStatus: action.payload,
                confirmBuySwapRequestStatusStarted: false
            };
        case CONFIRM_BUY_SWAP_REQUEST_FAILURE:
            return {
                ...state,
                confirmBuySwapRequestStatusErr: action.payload,
                confirmBuySwapRequestStatusStarted: false
            };
        case CONFIRM_SELL_SWAP_REQUEST_STARTED:
            return {
                ...state,
                confirmSellSwapRequestStatusStarted: true
            };
        case CONFIRM_SELL_SWAP_REQUEST_SUCCESS:
            return {
                ...state,
                confirmSellSwapRequestStatus: action.payload,
                confirmSellSwapRequestStatusStarted: false
            };
        case CONFIRM_SELL_SWAP_REQUEST_FAILURE:
            return {
                ...state,
                confirmSellSwapRequestStatusErr: action.payload,
                confirmSellSwapRequestStatusStarted: false
            };
        case GET_PROMOTIONS_NY_MAIN_DATA_STARTED:
            return {
                ...state,
                promotionsNyMainDataStarted: true
            };
        case GET_PROMOTIONS_NY_MAIN_DATA_SUCCESS:
            return {
                ...state,
                promotionsNyMainData: action.payload,
                promotionsNyMainDataStarted: false
            };
        case GET_PROMOTIONS_NY_MAIN_DATA_FAILURE:
            return {
                ...state,
                promotionsNyMainDataErr: action.payload,
                promotionsNyMainDataStarted: false
            };
        case CLEAR_CABINET_STATE:
            return {...initialState};
        default:
            return state;
    }
}

