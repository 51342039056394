import React from "react";
import Styles from './styles.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {setEventNyTaskModalStatus} from "../../store/cabinet/action";



const EventNyItem = (props) => {
    const {item, index, styleData} = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const dateHandler = (date) =>{
        const targetDate = new Date(date);
        const currentDate = new Date();

        return currentDate >= targetDate;
    }

    const taskHandler = (availableStatus) =>{
        if (item.tasks.length > 0 && availableStatus){
            dispatch(setEventNyTaskModalStatus({status: true, data: item.tasks}))
        }
    }

    return (
        <section className={`${Styles.container} ${Styles.honeeCard}`}>
            <div className={`${Styles.honeeCardBody}`}>
                <div className={`${Styles.contentWrap} ${dateHandler(item.start_date) ? null : Styles.contentWrapNotAvailable}`}>
                    <div className={`${Styles.imgWrap}`}>
                        <div className={`${Styles.imgWrapTitleRow}`}>
                            <div className={`${Styles.imgWrapTitleRowItem} ${Styles.titleWSF}`} style={{color: `${styleData[index].color}`}}>{t('Неделя')} #{index + 1}</div>
                            <div className={`${Styles.imgWrapTitleRowItem} ${Styles.titleWSF}`} style={{color: `${styleData[index].color}`}} onClick={()=>{taskHandler(dateHandler(item.start_date))}}>{t('Посмотреть задания')}</div>
                        </div>
                        <div className={`${Styles.imgBox}`} style={{border: `1px solid ${styleData[index].color}`}}>
                            <img src={item.completed_tasks === 0 ? styleData[index].imgs[item.completed_tasks] : styleData[index].imgs[item.completed_tasks - 1]} alt="tree"/>
                            <div className={`${Styles.imgBoxAbsMsg}`}>{t('Будет доступно с')} {item.start_date}</div>
                        </div>
                    </div>
                    <div className={`${Styles.taskWrap}`}>
                        <div className={`${Styles.taskWrapTitleRow}`}>
                            <div className={`${Styles.taskWrapTitleRowItem} ${Styles.titleWSF}`} style={{color: `${styleData[index].color}`}}>{t('Выполнено')}:</div>
                        </div>
                        <div className={`${Styles.taskBox} ${Styles.titleWSF}`} style={{border: `1px solid ${styleData[index].color}`, background: `${styleData[index].background}`, color: `${styleData[index].color}`}}>
                            {item.completed_tasks}/5
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EventNyItem;
