import React, {useEffect, useState} from "react";
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Seo from "../../components/_UI/seo";
import SwapMain from "../../components/swapMain";
import SwapRequest from "../../components/swapRequest";
import SwapRequestModal from "../../components/swapRequestModal";
import {
    clearAllSwapRequestList,
    // clearAllSwapRequestList,
    getAllSwapRequests, getAllSwapRequestsSuccess,
    getMySwapRequests,
    getMySwapRequestsSuccess,
    getSwapMainData
} from "../../store/cabinet/action";
import SwapRequestCancelModal from "../../components/swapRequestCancelModal";
import SwapRequestAll from "../../components/swapRequestAll";
import SwapRequestConfirmModal from "../../components/swapRequestConfirmModal";


const SwapPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authStatus = useSelector(store => store.main.authorizationStatus);
    const mySwapRequests = useSelector(store => store.cabinet.mySwapRequests);
    const allSwapRequests = useSelector(store => store.cabinet.allSwapRequests);

    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [isAllScrolledToEnd, setIsAllScrolledToEnd] = useState(false);
    const [currentFilter, setCurrentFilter] = useState(false);

    useEffect(()=>{
        if (authStatus.authorization){
            if (!authStatus.phrase){
                navigate('/confirmation');
            } else if (!authStatus.server){
                navigate('/server');
            }
        } else {
            navigate('/sign-in');
        }

    },[authStatus, navigate])

    useEffect(()=>{
        dispatch(getSwapMainData());
    },[dispatch])

    useEffect(()=>{
        if (!mySwapRequests){
            dispatch(getMySwapRequests(1));
        } else if (mySwapRequests.current_page + 1 <= mySwapRequests.last_page && isScrolledToEnd){
            dispatch(getMySwapRequests(mySwapRequests.current_page + 1));
            setIsScrolledToEnd(false);
        }

    },[dispatch, isScrolledToEnd]) // eslint-disable-line

    useEffect(() => {
        return (()=>{dispatch(getMySwapRequestsSuccess(false))})
    }, [dispatch]);

    useEffect(()=>{
        if (!allSwapRequests){
            dispatch(getAllSwapRequests(1, currentFilter));
        } else if (allSwapRequests.current_page + 1 <= allSwapRequests.last_page && isAllScrolledToEnd){
            dispatch(getAllSwapRequests(allSwapRequests.current_page + 1, currentFilter));
            setIsAllScrolledToEnd(false);
        }

    },[dispatch, isAllScrolledToEnd]) // eslint-disable-line

    useEffect(() => {
        return (()=>{dispatch(getAllSwapRequestsSuccess(false))})
    }, [dispatch]);

    useEffect(()=>{
        if (currentFilter){
            dispatch(clearAllSwapRequestList(true));
        }
    },[currentFilter, dispatch])

    return (
        <section className={`${Styles.container}`}>
            <Seo title={'P2P SWAP'}/>
            <div className={`${Styles.contentWrap}`}>
                <SwapMain/>
                <SwapRequest scrollAction={setIsScrolledToEnd}/>
                <SwapRequestAll scrollAction={setIsAllScrolledToEnd} currentFilter={currentFilter} setCurrentFilter={setCurrentFilter}/>
                <SwapRequestModal/>
                <SwapRequestCancelModal/>
                <SwapRequestConfirmModal/>
            </div>
        </section>
    );
};

export default SwapPage;