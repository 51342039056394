import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Styles from './styles.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {setEventNyTaskModalStatus} from "../../store/cabinet/action";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


const EventNyTaskModal = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const eventNyTaskModalStatus = useSelector(store => store.cabinet.eventNyTaskModalStatus);

    const closeModal = () =>{
        dispatch(setEventNyTaskModalStatus({status: false, data: false}))
    }

    const formatNumber = (value) => {
        if (value >= 1000) {
            return (value % 1000 === 0 ? (value / 1000).toFixed(0) : (value / 1000).toFixed(1)) + "к";
        }
        return Math.floor(value * 100) / 100 + "";
    }

    const taskContentHandler = (type) =>{
        switch (type) {
            case 'buy_index':
                return {
                    text: t('Купить Index'),
                    link: '/index'
                }
            case 'freeze_index':
                return {
                    text: t('Заморозить Index'),
                    link: '/index'
                }
            case 'stake_omt_tokens':
                return {
                    text: t('Стейкинг OMT_BEP20'),
                    link: '/staking'
                }
            case 'bot_refill':
                return {
                    text: t('Пополнить HoneeAI'),
                    link: '/honee'
                }
            case 'invite_friend':
                return {
                    text: `${t('Пригласить друга')} ⃰ `,
                    link: '/bonuses'
                }
            default:
                return {
                    text: 'unknown',
                    link: 'event/ny'
                }
        }
    }

    const taskBtnHandler = (type, status) =>{
        if (status){
            toast(t('Вы уже выполнили это задание!'))
        } else {
            closeModal();
            navigate(taskContentHandler(type).link);
        }
    }


    return (
        <Popup
            open={eventNyTaskModalStatus.status}
            modal
            nested
            closeOnDocumentClick
            onClose={closeModal}
            className='fixH'
        >
            <div className={`${Styles.modalContainer}`}>
                <div className={`${Styles.modalCloseBtn}`} onClick={()=>{closeModal()}}>
                    <div/>
                    <div/>
                </div>
                <div className={`${Styles.modalContentWrap}`}>
                    <div className={`${Styles.title}`}>{t('Задания')}</div>
                    <div className={`${Styles.taskWrap}`}>
                        {eventNyTaskModalStatus?.data ? eventNyTaskModalStatus?.data.map((item, index)=>{
                            return (
                                <div className={`${Styles.taskRow} ${item.completed ? Styles.taskRowComplete : null}`} key={'task' + index}>
                                    <div className={`${Styles.task}`}>{taskContentHandler(item.type).text}</div>
                                    <div className={`${Styles.taskRowInfoWrap}`}>
                                        <div className={`${Styles.taskRowInfoBox}`}>
                                            <div className={`${Styles.taskRowInfoBoxItem}`}>
                                                <div className={`${Styles.taskRowInfoBoxItemTitle}`}>{t('Условие')}:</div>
                                                <div className={`${Styles.taskRowInfoBoxItemValue} ${Styles.titleWSF}`}>{formatNumber(item.value * 1)} / {formatNumber(item.required_amount * 1)}</div>
                                            </div>
                                            <div className={`${Styles.taskRowInfoBoxItem}`}>
                                                <div className={`${Styles.taskRowInfoBoxItemTitle}`}>{t('Награда')}:</div>
                                                <div className={`${Styles.taskRowInfoBoxItemValue} ${Styles.titleWSF}`}>{item.reward * 1}</div>
                                            </div>
                                        </div>
                                        <div className={`${Styles.taskBtn}`} onClick={()=>{taskBtnHandler(item.type, item.completed)}}>{item.completed ? t('Выполнено') : t('Выполнить')}</div>
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                    <div className={`${Styles.noteWrap}`}>
                        ⃰ - {t('Для выполнения задания "Пригласи друга", необходимо чтобы он прошел регистрацию по вашей реферальной ссылке и активировал HoneeAi')}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default EventNyTaskModal;
