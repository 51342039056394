import React from "react";
import Styles from './styles.module.scss';
import {useTranslation} from "react-i18next";
import i18next from 'i18next';

import ecosystemPicture from '../../theme/assets/other/h2_main_present_ecosystem.png';



const MainPresentEcosystem = () => {
    const { t } = useTranslation();

    const btnHandler = () => {
        window.open(`https://s3.eu-central-1.amazonaws.com/honee2.0/omt_${i18next.language.toLowerCase()}.pdf`, "_blank")
    }

    return (
        <section className={`${Styles.container}`}>
            <div className={`${Styles.contentWrap} ${Styles.pageContainer}`}>
                <div className={`${Styles.textWrap}`}>
                    <div className={`${Styles.title2}`}>{t('Мы представляем вам')} <br/> <span>{t('экосистему OMT')}</span></div>
                    <div className={`${Styles.note}`}>
                        {t('Мы представляем глобальную экосистему OMT — пространство, где блокчейн и искусственный интеллект объединяются для создания инновационных решений')}.
                    </div>
                    <div className={`${Styles.note}`}>
                        {t('Наши продукты включают в себя OMT кошелек, токен OMT, индекс OMT20, HoneeAI и инструменты для эффективного использования технологий децентрализации')}.
                    </div>
                    <div className={`${Styles.note}`}>
                        {t('Узнайте, как OMT меняет подход к финансам и технологиям')}.
                    </div>
                    <div className={`${Styles.btnWrap}`}>
                        <div className={`${Styles.button}`} onClick={() => {btnHandler()}}>{t('Скачать презентацию')}</div>
                    </div>
                </div>
                <div className={`${Styles.pictureWrap}`}>
                    <div className={`${Styles.pictureBox}`}>
                        <img src={ecosystemPicture} alt="present ecosystem"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MainPresentEcosystem;